<script>
import Vue from "vue";
import store from "@/state/store";

import {required, email} from "vuelidate/lib/validators";

import {mapState} from "vuex";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/auth";
import {
	AccountStatus,
	notificationMethods, SessionLoginState
} from "@/state/helpers";


/**
 * Forgot Password component
 */
export default {
	page: {
		title: "E-Mail Verified",
		meta: [{name: "description", content: appConfig.description}]
	},
	components: {Layout},
	data() {
		return {};
	},
	computed: {},
	sockets: {},
	methods: {}
};
</script>

<template>
	<Layout>
		<div class="row justify-content-center">
			<div class="col-md-8 col-lg-6 col-xl-5">
				<div class="card">
					<div class="card-body">
						<div class="p-2">
              <div class="text-center">
                <div class="avatar-md mx-auto">
                  <div class="avatar-title rounded-circle bg-light">
                    <i class="bx bx-mail-send h1 mb-0 text-primary"></i>
                  </div>
                </div>
                <div class="p-2 mt-4">
                  <h3>
                    {{ $t('setup.email.verify.page.title') }}
                  </h3>
                  <p class="text-muted">
                    {{ $t('setup.email.verify.page.text') }}<br/>
                    {{ $t('setup.email.verify.page.prompt') }}
                  </p>
                </div>
              </div>
            </div>
					</div>
					<!-- end card-body -->
				</div>
				<!-- end card -->
			</div>
			<!-- end col -->
		</div>
		<!-- end row -->
		<div class="mt-3 text-center">
			<small>
				© 2017 - {{ new Date().getFullYear() }} {{ $t('generic.legal_disclaimer') }}
				{{ $t('generic.provider') }}<br>
				<small style="font-size: 10px;">
					<a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
					/
					<a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
				</small>
			</small>
		</div>
	</Layout>
</template>

<style lang="scss" module></style>
